import React from 'react';
import "./ServiceSpectrum.css";

import { useTranslation } from 'react-i18next';

const ServiceSpectrum = () => {

    const { t } = useTranslation("common");

    return (

        <div className="main-content">
            <div className="arrow-right">
                <a href="#">
                    <img src="assets/images/arrowLeft.png" />
                </a>
            </div>
            <div className="service-spectrum">{t("app.capabilities.servicespectrum.servicespectrum")}</div>
            <div className="main-cap-div">
                <div className="outshine-the-competition-text">
                    {t("app.capabilities.servicespectrum.outshinethecompetition")}
                </div>
                <div className="col2">
                    <a href="#">
                        <img className="imageSp" src="assets/images/goas-logo.png" />
                    </a>
                </div>
            </div>

            <div className="were-your-one-stop-digital-text">
                {t("app.capabilities.servicespectrum.weareyouronestepdigital")}
            </div>
        </div>
    )
}

export default ServiceSpectrum;