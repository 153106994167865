import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const ResourcesCard = ({ imageUrl, name, hyperLink }) => {

    const handleClick = () => {
        window.open(hyperLink, "_blank");
    }

    return (
        <div className="card border-0" onClick={handleClick} style={{ cursor: "pointer" }}>
            <img src={imageUrl} className="card-img-top" />
            <div className="card-body square border" style={{ backgroundColor: "#f5f5f5" }}>
                <div className="card-text name">{name}</div>
                <div className="arrowright">
                    <FontAwesomeIcon icon={faArrowRightLong} style={{ color: '#000' }} />
                </div>
            </div>
        </div>
    )
}

export default ResourcesCard;