import React from 'react';
import "./Footer.css";

import data from "./Footer.json";
import { Link } from 'react-router-dom';

const Footer = () => {

  return (

    <div className='main-container footer'>
      <nav>
        <div>
          <img src='assets/images/launch-nttdata-logo.png' alt='logo' />
        </div>
        <ul>
          {data.container1.data.map((item) => (
            <div key={item.id}>
              <li><Link to={item.routingurl}>{item.footername}</Link></li>
            </div>
          ))}
        </ul>
      </nav>

      <div className="verticle-line"></div>

      <nav>
        <div>
          <img src='assets/images/nttdata-logo.png' alt='logo' />
        </div>
        <div className='social-icons'>
          <a href="#">
            <img src="assets/images/twitter.png" alt="twitter" />
          </a>
          <a href="#">
            <img src="assets/images/linkedin.png" alt="linkedin" />
          </a>
          <a href="#">
            <img src="assets/images/facebook.png" alt="facebook" />
          </a>
          <a href="#">
            <img src="assets/images/socialmedia.png" alt="socialmedia" />
          </a>
          <a href="#">
            <img src="assets/images/instagram.png" alt="instagram" />
          </a>
        </div>
      </nav>

      <nav>
        <div>
          <ul>
            {data.container2.data.map((item) => (
              <div key={item.id}>
                <li><Link to={item.routingurl}>{item.footername}</Link></li>
              </div>
            ))}
          </ul>
        </div>
      </nav>

    </div>
  )
}

export default Footer;