import React from 'react';
import "./ResourcesLaunchified.css";

import { useTranslation } from 'react-i18next';

const ResourcesLaunchified = () => {

  const { t } = useTranslation("common");

  return (

    <div className="main-content">

      <div className="arrow-right">
        <a href="#">
          <img src="assets/images/arrowLeft.png" />
        </a>
      </div>

      <div>
        <div className="launchified">{t("app.resources.launchified.launchified")}</div>
      </div>

      <div>
        <div className="resources">{t("app.resources.launchified.resources")}</div>
      </div>

      <div className="Were-your-one-stop-text-resource">
        {t("app.resources.launchified.weareyouronestep")}
      </div>

    </div>

  )
}

export default ResourcesLaunchified;