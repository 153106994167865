import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Resources from "./Pages/Resources";
import Capabilities from "./Pages/Capabilities";
import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home";
import LeaderShipScreen from "./Pages/LeaderShipScreen";
import { motion } from "framer-motion";
import BeLaunchified from "./Pages/BeLaunchified";

function App() {
  return (
    <>
      <Router>
        <motion.div
          initial={{ y: "-100vh", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          style={{ minHeight: "100vh", overflow: "hidden" }}
        >
          <Navbar />
          <Route path="/" exact component={Home} />
          <Route path="/leadership" component={LeaderShipScreen} />
          <Route path="/resources" component={Resources} />
          <Route path="/capabilities" component={Capabilities} />
          <Route path="/belaunchified" component={BeLaunchified} />

          <Footer />
        </motion.div>
      </Router>
    </>
  );
}

export default App;
