import React from "react";
import ResourcesLaunchified from "../components/ResourcesPage/ResourcesLaunchified/ResourcesLaunchified";
import ResourcesCardView from "../components/ResourcesPage/ResourcesCardView/ResourcesCardView";
import ResourcesCaseStudy from "../components/ResourcesPage/ResourcesCaseStudy/ResourcesCaseStudy";
import { motion } from "framer-motion";
const Resources = () => {
  return (
    <div>
      <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        style={{ minHeight: "100vh", overflow: "hidden" }}
      >
        
        <div>
          <ResourcesLaunchified />
        </div>
        <div>
          <ResourcesCardView />
        </div>
        <div>
          <ResourcesCaseStudy />
        </div>
        
      </motion.div>
    </div>
  );
};

export default Resources;
