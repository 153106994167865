import React from 'react';
import "./CapabilitiesView.css";
import { useTranslation } from 'react-i18next';
import ListItems from './ListItems';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CapabilitiesView() {

  const { t } = useTranslation("common");

  return (

    <>
      <div className="capabilities-container">
        <div className="capabilities">{t("app.capabilities.capabilitiesview.capabilitiesview")}</div>
        <div className="continuous-teams-global-coverage">
          {t("app.capabilities.capabilitiesview.continousteams")}
        </div>

        <ListItems />

        <Row>
          <Col>
            <div className="ellipse-1"></div>
          </Col>
          <Col>
            <div className="ellipse-2"></div>
          </Col>
        </Row>

      </div>

    </>

  )
}

export default CapabilitiesView;