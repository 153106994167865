import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

function NavbarData() {
  return (
    <Navbar expand="lg" className="navbar-res">
      <Container>
        <Navbar.Brand href="home">
          <img
            className="launch-logo"
            src="./HomePageImages/launch_logo.png"
            alt="launch_logo"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="home">
              <img
                className="home_icon"
                src="./HomePageImages/homeicon.png"
                alt="home_icon"
              ></img>
            </Nav.Link>

            <NavLink to="/belaunchified" className="launchified_data">
              Be Launchified
            </NavLink>
            <NavLink to="/capabilities" className="launchified_data">
              Capabilities
            </NavLink>
            <NavLink className="launchified_data" to="/resources">
              Resources
            </NavLink>
            <Link to="/leadership" className="launchified_data">
              Leadership
            </Link>
            <Nav.Link className="user_data">Hi, User</Nav.Link>
            <Nav.Link className="arrow">
              <img
                className="arrow_icon"
                src="./HomePageImages/arrow.png"
                alt="arrow_icon"
              />
            </Nav.Link>
            <Nav.Link className="arrow">
              <img
                className="nttdata_icon"
                src="./HomePageImages/nttdata.png"
                alt="nttdata_icon"
              />
            </Nav.Link>
            <div className="Vector-2"></div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarData;
