import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./LaunchDetails.css";
function LaunchDetails() {
  return (
    <div>
      <div class="Vector-1"></div>

      <Container fluid className="launch-data">
        <Row>
          <span className="launch-academy">Launch Academy</span>
          <span className="learn-earn">
            Learn And Earn <br></br>Be Launchified.
          </span>
          <img className="imagePillar" src="4pillar.png" alt="pillar"></img>
          <button
            type="button"
            onClick={() =>
              window.open(
                "https://nttdatagroup.sharepoint.com/sites/SRVS-Launch/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=Jm7eBn&CID=e5862d59%2Db888%2D4bdb%2Daa7b%2D6c92a4178efd&FolderCTID=0x012000F8FA2CD18EBEF84FB146BBF3EC5E6A0C&id=%2Fsites%2FSRVS%2DLaunch%2FShared%20Documents%2F2024%20GTM%20Materials"
              )
            }
            className="buttonL btn btn-light"
          >
            Be Launchified
          </button>
          <span className="mission">
            <span className="text-style-1 fw-bold">Mission: </span>
            <span className="lh-sm">
              We exist to help you strategize, ship, and scale provocative
              digital experiences that <br></br>connect with your customers and
              drive growth.
            </span>
          </span>
        </Row>
      </Container>
      <div></div>
    </div>
  );
}
export default LaunchDetails;
