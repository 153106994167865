import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ResourcesCaseStudy.css";

import { useTranslation } from 'react-i18next';

function ResourcesCaseStudy() {

  const { t } = useTranslation("common");

  return (
    <div>
      <Container fluid className="resources-case-study-launch-data">

        <Row>
          <Col className="right-column">
            <span className="resources-case-study">{t("app.resources.casestudy.casestudy")}</span>
            <span className="resource-study-enrol">
              {t("app.resources.casestudy.ourclientarethecompanies")}
            </span>
            <p className="resource-study-paraS">
              {t("app.resources.casestudy.onestepdigital")}
            </p>
            <button type="button" className="btnExplore btn btn-light">
              {t("app.resources.casestudy.seeyourself")}
            </button>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default ResourcesCaseStudy;
