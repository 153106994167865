import React from "react";
import LaunchDetails from "../components/HomePage/LaunchDetails/LaunchDetails";
import WhyLaunchified from "../components/HomePage/WhyLaunchified/WhyLaunchified";
import HowToBeLaunchified from "../components/HomePage/HowToBeLaunchified/HowToBeLaunchified";
import { motion } from "framer-motion";

function Home() {
  return (
    <div>
        <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
      >
      <div>
        <LaunchDetails />
      </div>

      <div>
        <WhyLaunchified />
      </div>
      <div>
        <HowToBeLaunchified />
      </div>
      </motion.div>
    </div>
  );
}

export default Home;
