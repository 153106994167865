import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./WhyLaunchified.css";
function WhyLaunchified() {
  return (
    <div className="container-fluid">
      {/* Why Launchified section */}
      <h4 className="text1">Why Launchified</h4>
      <h1 className="text2"> Aim. Engage. Grow</h1>
      <span className="At-Launch">
        At Launch, our focus is on driving successful product launches through a
        proven model designed to help our clients thrive. We want you to
        understand how our
        <span className="text-style-1"> Aim, Engage, and Grow </span>
        model supports our clients at every step through our specially
        <span className="text-style-2">
          {" "}
          crafted & designed Launchified courses
        </span>
        .
      </span>
      <div className="Vector-3"></div>
      {/* Left side: Text and Button */}
      <div className="text-container">
        {/* Title */}
        <h3 className="text3">
          Aim, engage and <br />
          grow with us
        </h3>
        {/* Paragraph */}
        <p className="para1">
          The Aim, Engage, Grow model helps our clients ideate, <br />
          relate, and sustain innovative products from start to finish.
          <br />
          It's also engrained into the DNA of Launch by NTT DATA and <br />
          how we approach our employee's careers and growth.
        </p>
        {/* Button */}
        <button type="button" className="buttonE btn btn-dark">
          Explore Aim, Grow, Engage
        </button>
      </div>
      {/* Right side: Cards */}
      <div className="cards-container">
        {/* Card 1 */}
        <div className="card card1">
          <div className="card-body">
            <img src="./HomePageImages/aimicon.png" alt="Aim Icon" />
            <h1 className="textT card-title">Aim</h1>
            <p className="textP card-text">
              Find the career path that aligns with your skills and passions.
            </p>
          </div>
        </div>
        {/* Card 2 */}
        <div className="card card2">
          <div className="card-body">
            <img src="./HomePageImages/engageicon.png" alt="Engage Icon" />
            <h1 className="textT card-title">Engage</h1>
            <p className="textP card-text">
              Bring your authentic self to work. Here, everyone is welcome and
              supported.
            </p>
          </div>
        </div>
        {/* Card 3 */}
        <div className="card card3">
          <div className="card-body">
            <img src="./HomePageImages/growicon.png" alt="Grow Icon" />
            <h1 className="textGrow card-title">Grow</h1>
            <p className="textPGrow card-text">Grow your career with us.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhyLaunchified;
