import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HowToBeLaunchified.css";
function HowToBeLaunchified() {
  return (
    <div>
      <Container fluid className="how-to-be-launch-data">
        <Row>
          <Col className="right-column">
            <span className="how-to-be-launchified">How to be Launchified</span>
            <span className="enrol">
              Enroll In The Specially Crafted Courses And Get Launchified.
            </span>
            <p className="paraL">
              At Launch Academy, we invite our employees and clients to join
              specially designed courses that align with our launch mission. By
              successfully completing the courses, you’ll earn a gamified
              certificate and become a proud member of our launchified team.
            </p>
            <button type="button" className="btnExplore btn btn-light">
              Explore Launchify
            </button>
          </Col>
          <Col className="left-column">
            <img
              className="manImage"
              src="./HomePageImages/Manimage.png"
              alt="pillar"
            />
          </Col>
        </Row>
      </Container>

      <div className="surrounding-container">
        <Container className="launch-details">
          <h1 className="headL">
            Launch is for the bold
            <span className="be-launchified">Be Launchified.</span>
          </h1>
          <p className="at-launch">
            At Launch, our focus is on driving successful product launches
            through a proven model designed to help our clients thrive. We want
            you to understand how our{" "}
            <span className="text-style-1"> Aim, Engage, and Grow model</span>
            supports our clients at every step through our specially
            <span className="text-style-2">
              {" "}
              crafted & designed Launchified courses.
            </span>
            .
          </p>
          <button type="button" className="btnS btn btn-light">
            Be Launchified
          </button>
        </Container>
      </div>
    </div>
  );
}
export default HowToBeLaunchified;
